import { defineAsyncComponent } from "vue";
const modules = import.meta.glob("../components/**/**.vue");

export default {
	install(app, options) {
		// 根据modules加载所有组件
		for (const key in modules) {
			if (modules.hasOwnProperty(key)) {
				const module = modules[key];
				const arr = key.split("/");
				const lastName = arr[arr.length - 1];
				const componentName = lastName === "index.vue"
					? arr[arr.length - 2]
					: lastName.split(".vue")[0];

				// 使用原生JavaScript实现camelCase和upperFirst
				const formattedComponentName = upperFirst(camelCase(componentName));
				app.component(formattedComponentName, defineAsyncComponent(module));
			}
		}
	}
}

// 将字符串转换为camelCase形式的函数
function camelCase(str) {
	return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

// 将字符串首字母大写的函数
function upperFirst(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}